import * as icons from 'lucide-react'
import { Size, SizeType } from '@/types/system'
import { LucideIcon } from 'lucide-react'

type AvailableIcons = keyof typeof icons

export type IconProps = {
  name: AvailableIcons
  size: SizeType
  customClass?: string
}
export const Icon = ({ name, size, customClass }: IconProps) => {
  const ChosenIcon = icons[name] as LucideIcon

  let calculatedSize = 16

  if (size === Size.Medium) {
    calculatedSize = 20
  }

  if (size === Size.Large) {
    calculatedSize = 24
  }

  if (size === Size.ExtraLarge) {
    calculatedSize = 28
  }

  if (size === Size.ExtraExtraLarge) {
    calculatedSize = 40
  }

  return (
    <ChosenIcon
      className={customClass ? customClass : ''}
      size={calculatedSize}
    />
  )
}
